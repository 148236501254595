<template>
    <div class="scrolls" ref="my_scroller" @scroll="scroll($event.target)">

        <div class="scroll-box" ref="my_scroller_box" :style="diff?{transform: `translate3d(0, ${diff}px, 0)` }:''">
            <div v-if="topLoadMethod" :style="{ height: `${topBlockHeight}px`,top: `-${topBlockHeight}px` }"
                class="action-block bg-success" v-show="showPullRefresh">
                <p class="m-0">
                    <i class="fa mr-1" :class="pullIcon"></i>
                    <strong class="text-white" v-text="refreshText"></strong>
                </p>
            </div>
            <div class="scroll-container">
                <slot></slot>
            </div>
            <div class="load" ref="load" v-if="onInfinite">
                <p v-if="loadingState==0">{{noDataText}}</p>
                <p v-else-if="loadingState==3" @click="loadingState =1 ;deInit()">加载失败，点击重试</p>
                <p v-else>
                    <svg class="load-icon" viewBox="0 0 64 64">
                        <use xlink:href="#icon-loading"></use>
                    </svg>
                    <span>Loading...</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        data() {
            return {
                loadingState: 1, // 0: stop, 1: loading, 2: stopping loading ,3:loading error
                diff: 0,
                beforeDiff: 0,
                topLoadMethod: false,
                topBlockHeight: 50,
                startY: 0,
                startScrollTop: 0,
                distance: 0,
                refreshText: this.$t('tables.loading'),
                state: '',
                triggerDistance: 70,
                parentnode: false,
                scrollContainer: null,
                scrollBox : null,
            }
        },
        props: {
            onRefresh: Function,
            onInfinite: Function,
            showPullRefresh: {
                type: Boolean,
                default: true,
            },
            noDataText: {
                type: String,
                default: 'No more data'
            },
        },
        computed: {
            pullIcon() {
                // let state = this.state || this.state === 'trigger'
                let icon = ''
                if (this.state === 'pull') {
                    icon = 'fa-arrow-circle-down';
                } else if (this.state === 'loading') {
                    icon = 'fa-spinner fa-spin';
                } else if (this.state === 'loaded-done') {
                    icon = 'fa-check-circle';
                }
                return icon;
            }
        },
        created() {

        },
        mounted() {
            this.deInit();
            this.topLoadMethod = !!this.onRefresh
            this.bindEvents()
        },
        methods: {
            deInit: _.debounce(function () {
                if (!this.onInfinite) return
                if (this.loadingState === 0 || this.loadingState === 2 || this.loadingState === 3) return
                if (this.$refs.my_scroller.clientHeight >= this.$refs.load.offsetTop) {
                    this.loadingState = 2
                    this.onInfinite(this.finishInfinite)
                    this.deInit()
                } else {
                    if ((this.$refs.my_scroller.scrollTop + this.$refs.my_scroller.clientHeight - this.$refs
                            .load.clientHeight) > (this.$refs.load.offsetTop - 50)) {
                        this.loadingState = 2
                        this.onInfinite(this.finishInfinite)
                    }
                }
            }, 300, {
                'maxWait': 1000
            }),
            finishInfinite(hideSpinner) {
                let states = 1
                switch (hideSpinner) {
                    case 'end':
                        states = 0
                        break
                    case 'error':
                        states = 3
                        break
                    default:
                        states = 1
                }
                this.loadingState = states
            },
            reload() {
                this.loadingState = 1
                this.$refs.my_scroller.scrollTop = 0
                this.deInit()
            },
            scroll(event) {
                this.deInit();
            },
            scrollTo(y, duration = 200) {
                this.$refs.my_scroller_box.style.transition = `${duration}ms`;
                this.diff = y;
                let timeout = setTimeout(() => {
                    this.$refs.my_scroller_box.style.transition = '';
                    window.clearTimeout(timeout)
                }, duration);
            },
            findParent(node) {
                if (node.className.indexOf('no_scroll') > -1) {
                    this.parentnode = true
                } else {
                    this.parentnode = false
                    if (node.parentNode.nodeName.toLowerCase() != 'body') {
                        this.findParent(node.parentNode)
                    }
                }

            },
            touchstart(event) {
                this.startY = event.touches[0].clientY
                this.beforeDiff = this.diff;
                this.startScrollTop = this.scrollContainer.scrollTop
                // console.log( 'this.scrollContainer.scrollTop' , this.scrollContainer.scrollTop );
                // this.startScrollTop = this.$refs.my_scroller.scrollTop
                this.state = 'pull'
                this.refreshText = this.$t('messages.pullDown')
                this.topLoadMethod = false
            },
            touchmove(event) {
                this.findParent(event.target)
                if (this.parentnode) {
                    return
                }
                this.currentY = event.touches[0].clientY;
                this.distance = (this.currentY - this.startY) / 2 + this.beforeDiff;
                if (this.startScrollTop < 2 && this.distance > 0) {
                    this.topLoadMethod = true
                    event.preventDefault();
                    event.stopPropagation();
                    this.diff = this.distance;
                    if (this.distance > this.triggerDistance) {
                        this.state = 'trigger'
                        this.refreshText = this.$t('messages.letGo');
                    } else {
                        this.state = 'pull'
                        this.refreshText = this.$t('messages.pullDown')
                    }
                }
            },
            touchend() {
                if (this.topLoadMethod) {
                    if (this.diff < this.triggerDistance) {
                        this.scrollTo(0)

                    } else {
                        this.state = 'loading'
                        this.scrollTo(this.topBlockHeight);
                        this.onRefresh(() => {
                            this.refreshText = this.$t('messages.downloadCompleted');
                            this.state = 'loaded-done'
                            let timeout = setTimeout(() => {
                                this.scrollTo(0)
                                this.reload();
                                window.clearTimeout(timeout)
                            }, 400);
                        })
                        this.refreshText = this.$t('tables.loading');
                    }

                }

            },
            bindEvents() {
                this.scrollContainer = document.getElementsByTagName('body')[0];
                if (this.topLoadMethod) {
                    this.$refs.my_scroller_box.addEventListener('touchstart', this.touchstart);
                    this.$refs.my_scroller_box.addEventListener('touchmove', this.touchmove);
                    this.$refs.my_scroller_box.addEventListener('touchend', this.touchend);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>

    .scrolls {
        display: flex;
        // overflow-y: auto;
        // overflow: overlay;
        // height: calc(100vh - 62px);
        // width: 100%;
        // padding-bottom: 1rem;
    }
    // @media only screen and (max-width: 767px) {
    //     .scrolls {
    //         height: calc(100vh - 62px) !important;
    //     }
    // }
 

    .scrolls .scroll-box {
        width: 100%;
    }

    .action-block {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        min-height: .9rem;
        width: 100%;

        /* background: rgb(129, 178, 58);
        background: linear-gradient(0deg, rgba(129, 178, 58, 1) 80%, rgba(255, 255, 255, 1) 100%); */
    }

    .action-block svg {
        display: block;
        margin-right: .2rem;
        width: .3rem;
        height: .3rem;
        fill: #555;
        will-change: transform;
    }

    .action-block svg.icon-arrow {
        transition: .2s;
        transform: rotate(180deg);
    }

    .load {
        text-align: center;
    }

    .load p {
        font-size: .26rem;
        color: #555;
        line-height: .8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: .9rem;
        margin: 0;
    }

    .load-icon {
        display: block;
        margin-right: .2rem;
        width: .3rem;
        height: .3rem;
        fill: #555;
        will-change: transform;
    }
</style>